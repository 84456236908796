import React, { useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';

import { HeartLoader } from '../../../../../../prototype/src/components/HeartLoader';
import {IBlogPostProps, BlogPost} from '../../../../../../prototype/src/components/BlogPost'

interface IBlogPostBlockInfo {
    title: string,
    ctaUrl: string,
    ctaText: string,
    ctaUrlTarget: string,
    blogUrl: string,
    blogDivId: string,
}

declare global{
    interface Window {blogPostBlockInfo: IBlogPostBlockInfo}
}

const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.001,
};

const BlogPostsComponent = () => {

    const containerRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [blogPosts, setBlogPosts] = useState<IBlogPostProps[]>([]);

    
    const getRSSData = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'User-Agent': 'ValleyMed.org Query',
                'cache-control': 'no-cache',
            }
        };

        fetch(window.blogPostBlockInfo.blogUrl, requestOptions)
            .then((response) => response.text())
            .then((xmlText) => {
                const parser = new DOMParser();
                const xml = parser.parseFromString(xmlText.replace('\n', '\r\n'), 'text/xml'); // replace \n with \r\n otherwise you will get "XML Parsing Error: not well-formed" error
                var items = xml.querySelectorAll('item');
                var posts : IBlogPostProps[] = [];
                for (let i = 0; i < items.length; i++) {
                    try { // skip any blog posts that cause problems.
                        let item = items[i];
                        if (item !== null){
                            let title = item.querySelector('title')?.innerHTML;
                            let desc = item.querySelector('description')?.innerHTML;
                            let imgMatch = desc?.match(/<img.+?src=[\"'](.+?)[\"'].*?>/i);
                            var imgLink;
                            if (imgMatch)
                                imgLink = imgMatch[1];
                            // Remove HTML tags and non-breaking spaces leaving a plain description.
                            let subText = desc?.replace(/<[^>]+>|&nbsp;/g, ' ').trim();
                            if (subText){
                                // Truncate the description down to 60 characters or the length of the description, whichever is shorter.
                                var subTextTrunLength = Math.min(subText.length, 60);
                                subText = subText.substring(0, subTextTrunLength).concat('...');
                                // make sure to convert html entities in the text back to readable text - such as &#8217; for apostraphe
                                var subTextElem = document.createElement('p');
                                subTextElem.innerHTML = subText;
                                subText = subTextElem.textContent || subTextElem.innerText;

                            }
                            let isFavored = false;
                            item.querySelectorAll('category').forEach(categoryElem => {if (isFavored) return; else if (categoryElem.innerHTML.includes('homepg')) isFavored = true});
                            let publishDateElem = item.querySelector('pubDate');
                            var publishDate;
                            if (publishDateElem?.textContent)
                                publishDate = new Date(publishDateElem?.textContent);
                            var postLink = item.querySelector('link')?.innerHTML;
                            if (postLink)
                                posts.push({
                                                title: title || '', 
                                                url: postLink, 
                                                imgUrl: imgLink || '', 
                                                postSummery: subText || '',
                                                isFavored: isFavored,
                                                publishDate: publishDate,
                                            });
                            
                        }
                    }
                    catch(ex) {
                        console.log('blog post parse error', ex);
                    }
                }
                // sort by wheather or not they are favored then sort by pubDate decending.
                let sortFunc = (a: IBlogPostProps, b: IBlogPostProps) => {
                    if (a.isFavored == true && b.isFavored == false) 
                        return -1;
                    else if (a.isFavored == false && b.isFavored == true)
                        return 1;
                    else if (a.publishDate > b.publishDate)
                        return -1;
                    else if (a.publishDate > b.publishDate)
                        return 1;
                    else
                        return 0;

                     
                }
                posts = posts.sort(sortFunc).slice(0,3);
                setBlogPosts(posts);
            });
       
    };
    
    const observeCallback = (entries, observer) => {
        entries.forEach(function (entry, index) {
            if (entry.isIntersecting && !isLoaded) {
                setIsLoaded(true);
                getRSSData();
                observer.disconnect();
            }
        })
    };

    useEffect(() => {
        if (containerRef.current){
            let observer = new IntersectionObserver(observeCallback, options);
            observer.observe(containerRef.current);
        }
    }, [containerRef, options]);
    
   return <div ref={containerRef}>
            <div className="updates_from_valey">
                <div className="content_contain">
                    <h2>{window.blogPostBlockInfo.title}</h2>
                    <div id="blog_container" className="secondary_contain">
                        { blogPosts.length > 0 ? (
                            blogPosts.map(post => 
                                                <BlogPost title={post.title} 
                                                        url={post.url} 
                                                        imgUrl={post.imgUrl} 
                                                        postSummery={post.postSummery}
                                                        publishDate={post.publishDate}
                                                        isFavored={post.isFavored} />
                                            ) 
                                        ) : (   
                                                <div>
                                                    <h2 style={{textAlign: "center", fontSize: "2.9rem"}}>Loading blog posts...</h2>
                                                    <HeartLoader/>
                                                </div>
                                        )
                                    }
                    </div>
                    <a className="cta" href={window.blogPostBlockInfo.ctaUrl} target={window.blogPostBlockInfo.ctaUrlTarget}>{window.blogPostBlockInfo.ctaText}</a>
                </div>
            </div>
          </div>
    
};


if (window.blogPostBlockInfo)
    render(<BlogPostsComponent/>, document.getElementById(window.blogPostBlockInfo.blogDivId));

    