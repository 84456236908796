import React from 'react';

export interface IBlogPostProps {
    title: string;
    url: string;
    imgUrl: string;
    postSummery: string;
    publishDate: Date;
    isFavored: boolean;
};

export const BlogPost = ({title, url, imgUrl, postSummery} : IBlogPostProps) => {
    return <div className="post_contain">
                <div className="image_contain">
                    <img src={imgUrl} alt=""/>
                </div>
                <a href={url}>{title}</a>
                <p>{postSummery}</p>
            </div>

};